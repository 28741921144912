import React from "react"

// Features
import Layout from "../features/Layout"
import SEO from "../features/Seo"

import { PrivacyPolicy } from "shared-ui"

const PrivacyPage = () => {
  const privacyPageData = {
    noSupporters: true,
    hero: null,
    footer: {
      download: false,
    },
  }

  return (
    <Layout pageData={privacyPageData}>
      <SEO title="Privacy Policy" />
      <PrivacyPolicy />
    </Layout>
  )
}

export default PrivacyPage
